import { Tag, Col, Icon, Cell, CellGroup, Field, Toast, Tabbar, TabbarItem, NavBar, Calendar } from "vant";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { getUser, getAuthority, adminChargeOff, adminCancel, exportExcel } from "@/api/services.js";
import modal from "@/component/modal.vue";
export default {
  setup() {
    let router = useRouter();
    const showCancelDialog = ref(false);
    const showExcelDialog = ref(false);
    const active = ref(2);
    const exportDate = ref("");
    const searchPhone = ref("");
    const adminphone = ref("");
    const noAuthority = ref(true);
    const phone = ref(router.currentRoute.value.query.phone);
    const pageTitle = ref("管理中心-" + phone.value);
    const tabBars = ref(["/book?phone=" + phone.value, "/check?phone=" + phone.value, "/admin?phone=" + phone.value]);
    const mealDate = ref("");
    const mealType = ref("");
    const reason = ref("");
    const mealTypeText = ref("");
    const userName = ref("");
    const department = ref("");
    const showSussess = ref(false);
    const showCalendar = ref(false);
    const showExportCalendar = ref(false);

    const getPerson = () => {
      let obj = {
        phone: phone.value
      };
      getAuthority(obj).then(res => {
        //console.log("getAuthority");
        if (res.data) {
          if (res.data.code == 0) {
            //接收后台返回的数据，动态绑定到界面
            noAuthority.value = false;
          } else if (res.data.code == 1) {
            noAuthority.value = true;
          }
        } else {
          Toast.error("信息有误");
        }
      });
    };

    exportDate;

    const formatDate = date => {
      console.log(date);
      console.log(date.getFullYear());
      let result = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      console.log(result);
      return result;
    };

    const onConfirm = value => {
      showCalendar.value = false;
      mealDate.value = formatDate(value); //alert(mealDate.value);
    };

    const onExportConfirm = values => {
      showExportCalendar.value = false;
      const [start, end] = values; //exportDate.value = formatDate(value);

      exportDate.value = `${formatDate(start)} to ${formatDate(end)}`; //alert(mealDate.value);
    };

    const onChoose = value => {
      mealType.value = value;

      switch (value) {
        case "1":
          mealTypeText.value = "早餐(晚班)";
          break;

        case "2":
          mealTypeText.value = "午餐(白班)";
          break;

        case "3":
          mealTypeText.value = "晚餐(白班)";
          break;

        case "4":
          mealTypeText.value = "夜宵(晚班)";
          break;
      }
    };

    const isChoosed = item => {
      return mealType.value == item;
    };

    const addDays = days => {
      let date = new Date();
      date.setDate(date.getDate() + days);
      return date;
    };

    const onSearch = () => {
      let obj = {
        phone: searchPhone.value
      };
      console.log(obj);
      getUser(obj).then(res => {
        console.log(searchPhone);
        console.log(res.data);

        if (res.data.code == 0) {
          //接收后台返回的数据，动态绑定到界面
          userName.value = res.data.data.userName;
          department.value = res.data.data.department;
        } else {
          Toast("无法获取信息，请稍候重试");
        }
      }, error => {
        console.log(error); // this.$message.error(error.message);
      });
    };

    const adminSubmit = () => {
      let obj = {
        AdminPhone: phone.value,
        phone: searchPhone.value,
        mealDate: mealDate.value,
        mealType: mealType.value,
        reason: reason.value
      };

      if (!searchPhone.value || !mealDate.value || !mealType.value || !reason.value) {
        Toast("员工手机号,餐别,计餐日期,原因均不能为空");
      } else {
        adminChargeOff(obj).then(res => {
          console.log(res.data);

          if (res.data.code == 0) {
            if (res.data.message == "已核销") {
              Toast("核销失败-" + res.data.message);
            } else {
              Toast.success("核销成功");
            }
          } else {
            Toast("无法提交信息，请稍候重试");
          }
        }, error => {
          console.log(error); // this.$message.error(error.message);
        });
      }
    }; // adminCancel(obj).then((res) => {
    //       if (res.data.code == 0) {
    //         Toast("取消成功");
    //         hideModal();
    //       } else {
    //         showCancelDialog.value = false;
    //         Toast("取消失败-" + res.data.message);
    //       }
    //     });


    const minDate = addDays(-60);
    const maxDate = addDays(1);

    const hideModal = () => {
      // 取消弹窗回调
      showCancelDialog.value = false;
      reason.value = "";
    };

    const ExcelhideModal = () => {
      // 取消导出弹窗回调
      showExcelDialog.value = false;
    };

    const showModal = () => {
      let obj = {
        AdminPhone: phone.value,
        phone: searchPhone.value,
        mealDate: mealDate.value,
        mealType: mealType.value,
        reason: reason.value
      };

      if (!obj.phone || !obj.mealDate || !obj.mealType || !obj.reason) {
        Toast("员工手机号,餐别,计餐日期,原因 均不能为空");
      } else {
        // 取消弹窗回调
        showCancelDialog.value = true;
      }
    };

    const excelAdminSubmit = () => {
      let obj = {
        phone: phone.value,
        exportDate: exportDate.value
      };

      if (obj.phone && obj.exportDate) {
        showExcelDialog.value = true;
      } else {
        // 取消导出弹窗回调
        Toast("Excel数据导出日期不能为空");
      }
    }; //取消


    const submitCancel = () => {
      let obj = {
        AdminPhone: phone.value,
        phone: searchPhone.value,
        mealDate: mealDate.value,
        mealType: mealType.value,
        reason: reason.value
      };

      if (obj.phone && obj.mealDate && obj.mealType && obj.reason) {
        adminCancel(obj).then(res => {
          if (res.data.code == 0) {
            Toast("取消成功");
            hideModal();
          } else {
            showCancelDialog.value = false;
            Toast("取消失败-" + res.data.message);
          }
        });
      } else {
        Toast.error("员工手机号,餐别,计餐日期，原因均不能为空");
      }
    }; //导出excel


    const Excelsubmit = () => {
      let obj = {
        phone: phone.value,
        exportDate: exportDate.value,
        fileName: exportDate.value + "_report.xlsx"
      };
      exportExcel(obj).then(res => {
        if (res != null) {
          //console.log(res.data);
          Toast("导出成功");
          ExcelhideModal();
        } else {
          showExcelDialog.value = false;
          Toast("暂无权限");
        }
      });
    };

    return {
      active,
      mealDate,
      showCalendar,
      onConfirm,
      minDate,
      maxDate,
      reason,
      searchPhone,
      adminphone,
      exportDate,
      showExportCalendar,
      onExportConfirm,
      noAuthority,
      pageTitle,
      getUser,
      getAuthority,
      getPerson,
      onSearch,
      userName,
      department,
      adminSubmit,
      excelAdminSubmit,
      onChoose,
      showSussess,
      showCancelDialog,
      showExcelDialog,
      hideModal,
      ExcelhideModal,
      submitCancel,
      Excelsubmit,
      adminCancel,
      exportExcel,
      showModal,
      mealTypeText,
      isChoosed,
      tabBars
    };
  },

  created() {
    this.getPerson();
  },

  components: {
    [Tag.name]: Tag,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [NavBar.name]: NavBar,
    [Calendar.name]: Calendar,
    modal
  }
};